import React, { useState } from 'react';
import styled from 'styled-components';
import Button from "./Button";
import axios from "axios";

const StyledContactForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 80px;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;

  .form-fields {
    display: flex;
    flex-grow: 1;
    margin: 0 -25px;

    @media (max-width: 835px) {
      flex-wrap: wrap;
      gap: 30px;
    }
  }

  .project-details {
    @media (max-width: 835px) {
      margin-top: -50px;
    }
  }

  .form-field {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 0;

    @media (max-width: 835px) {
      flex-basis: 100%;
    }
  }

  .form-field-inner {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding: 0 25px;
  }

  input {
    flex-basis: 100%;
    height: 40px;
    line-height: 40px;
    padding: 0;
    padding-bottom: 10px;
    border: none;
    border-bottom: solid var(--heading-color) 6px;
    color: var(--heading-color);
    background-color: transparent;

    &:focus {
      outline: none;
      color: var(--pixel-red);
    }
  }

  label {
    font-weight: bold;
    padding-top: 2px;
    color: var(--heading-color);
  }

  .submit-button {
    &:disabled {
      background-color: var(--blue-gray);
    }

    &--submitting {
      cursor: wait;
    }

    &--submitted {
      cursor: not-allowed;
    }
  }

  .alert {
    padding: 10px 20px;
    border: none;
    border-left: solid 10px;
    border-radius: 10px;

    &--success {
      color: var(--dark-navy);
      background-color: #39d895;
      border-left-color: #22875c;
    }

    &--danger {
      color: white;
      background-color: var(--pixel-red);
      border-left-color: #D12727;
    }
  }
`;

export default function ContactForm() {

  const [formSubmitted, toggleFormSubmitted] = useState(false);
  const [formFinished, toggleFormFinished] = useState(false);
  const [formSucceeded, toggleFormSucceeded] = useState(false);

  // poss the form submission to Nexus for processing
  const submitForm = (e) => {
    e.preventDefault();
    toggleFormSubmitted(true);

    const form = e.target.parentNode.parentNode; // form element
    const formData = new FormData(form);

    const csrfEndpoint = 'sanctum/csrf-cookie';
    const contactEndpoint = 'contact';

    // set up our api connection with axios
    const api = axios.create({
      baseURL: process.env.GATSBY_NEXUS_URL,
      withCredentials: true,
      withXSRFToken: true,
    })

    // set up CSRF session with Nexus
    api.get(csrfEndpoint)
      .then((response) => {
        // submit contact form
        api.post(contactEndpoint, new URLSearchParams(formData).toString(), {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        })
          .then((response) => showFormFeedback(response))
          .catch((error) => showError(error.code, error.message));
      })
      .catch((error) => {console.log(error);showError(error.code, error.message)});
  }

  // display feedback after a form has been submitted
  const showFormFeedback = (response) => {
    toggleFormFinished(true);
    toggleFormSucceeded(response.data == 1);
  }

  // log an error message
  const showError = (code, message) => {
    toggleFormFinished(true);
    toggleFormSucceeded(false);
    console.error(`There was an error connecting to the contact submission server: ${code} (${message})`)
  }

  return (
    <StyledContactForm
      formSubmitted={formSubmitted}
      formFinished={formFinished}
      formSucceeeded={formSucceeded}
    >
      <div className="form-fields">
        <div className="form-field">
          <div className="form-field-inner">
            <input type="text" name="name"></input>
            <label htmlFor="name">Name</label>
          </div>
        </div>

        <div className="form-field">
          <div className="form-field-inner">
            <input type="text" name="email-phone"></input>
            <label htmlFor="email-phone">Email or Phone</label>
          </div>
        </div>

        <div className="form-field">
          <div className="form-field-inner">
            <input type="text" name="company"></input>
            <label htmlFor="company">Company</label>
          </div>
        </div>
      </div>

      <div className="form-field project-details w-100">
        <input type="text" name="details"></input>
        <label htmlFor="details">Tell us about your project</label>
      </div>

      <div className="flex-start w-100">
        <Button classes={`button--large submit-button ${formSubmitted ? (formFinished ? 'submit-button--submitted' : 'submit-button--submitting') : ''}`} children={formSubmitted ? (formFinished ? 'Submitted' : 'Submitting, please wait...') : 'Submit'} onClick={submitForm} disabled={formSubmitted ? 'disabled' : ''} />
      </div>

      { formFinished &&
        <div className={`alert ${formSucceeded ? 'alert--success' : 'alert--danger'}`}>
          { formSucceeded
            ? 'Thanks for getting in touch! We\'ll get back to you as soon as we can.'
            : 'Sorry, there was an error with your submission. Please try again later!'
          }
        </div>
      }
    </StyledContactForm>
  );
}
