import React, { useState } from 'react';
import styled from 'styled-components';
import Chevron from '../assets/images/chevron-down.svg';
import ContactForm from './ContactForm';

const StyledPreFooter = styled.footer`
  position: relative;
  padding-top: 8.125rem;
  padding-bottom: 50px;

  .button-reset {
    outline: none;
    border: 0;
    background: none;
  }

  .prefooter {
    &__content {
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    &__trigger {
    }

    &__heading {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 30px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 80px;
      cursor: pointer;

      @media (min-width: 992px) {
        font-size: 55px;
      }
    }
  }

  .contactButton {
    margin-top: 4rem;
    text-align: center;

    a {
      display: inline-block;
      padding: 1rem 1.75rem;
      background-color: var(--button-bg-color);
      border-radius: var(--border-radius-medium);
      font-family: var(--button-font);
      color: var(--button-color);
      text-decoration: none;
      line-height: 1;
      padding: 1.3rem;
      font-size: 1.2rem;

      &:hover {
        background-color: var(--button-hover-bg-color);
        color: var(--button-hover-color);
      }
    }
  }

  .floating-pixels {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    .floating-pixel {
      position: absolute;
    }

    .pixel-1 {
      top: 4rem;
      left: 10vw;
    }

    .pixel-2 {
      top: 15rem;
      right: 9vw;

      @media all and (min-width: 992px) {
        top: 9rem;
      }
    }

    .pixel-3 {
      bottom: 3rem;
      left: 13vw;
    }
  }
`;

const StyledChevron = styled(Chevron)`
  width: 100px;
  height: 20px;
  margin-top: 10px;
  transition: transform 0.2s ease-out;
  transform: ${(props) => (props.open ? 'rotate(180deg)' : 'rotate(0)')};

  @media all and (max-width: 992px) {
    width: 60px;
    height: 15px;
    margin-top: 5px;
  }

  path {
    fill: var(--heading-color);
  }
`;

const ContactFormPanel = styled.div`
  overflow: hidden;
  transition-property: max-height, padding-bottom, opacity;
  transition-duration: 0.4s;
  transition-timing-function: ease-out;
  display: block;
  height: auto;
  max-height: ${(props) => (props.open ? '600px' : '0')};
  padding-bottom: ${(props) => (props.open ? '80px' : '0')};
  opacity: ${(props) => (props.open ? '1' : '0')};
`;

export default function PreFooter(props) {
  const [contactFormOpen, toggleContactFormOpen] = useState(false);

  function toggleContactForm() {
    toggleContactFormOpen(!contactFormOpen);
  }

  return (
    <StyledPreFooter
      className="prefooter bg-blue-gradient section-padding-y padding-x-12"
      open={contactFormOpen}
      id="contact"
    >
      <div className="prefooter__content">
        <button
          className="button-reset reset-button prefooter__trigger"
          onClick={toggleContactForm}
        >
          <h3 className="prefooter__heading">
            {props.heading ? props.heading : 'Looking to work with us?'}
            <StyledChevron open={contactFormOpen} className="chevron" />
          </h3>
        </button>
        <ContactFormPanel open={contactFormOpen}>
          <ContactForm />
        </ContactFormPanel>
      </div>
      <div className="floating-pixels">
        <div className="floating-pixel floating-pixel--red floating-pixel--xs floating-pixel--rotate-70 pixel-1"></div>
        <div className="floating-pixel floating-pixel--red floating-pixel--xxs floating-pixel--rotate-35 pixel-2"></div>
        <div className="floating-pixel floating-pixel--gray floating-pixel--xl floating-pixel--opacity-15 floating-pixel--rotate-26 pixel-3"></div>
      </div>
    </StyledPreFooter>
  );
}
