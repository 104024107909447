import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Nav from './Nav';
import Footer from './Footer';


import 'normalize.css';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';

export default function Layout({ children }) {
  const data = useStaticQuery(graphql`
    query MenuQuery {
      site {
        siteMetadata {
          title
          description
          siteUrl
          menuLinks {
            link
            name
            class
            parent
            subMenu {
              class
              link
              name
              description
            }
          }
        }
      }
    }
  `);
  return (
    <>
      <GlobalStyles />
      <Typography />
      <Nav menuLinks={data.site.siteMetadata.menuLinks} />
      {children}
      <Footer />
    </>
  );
}
