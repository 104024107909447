import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Chevron from '../assets/images/chevron-down.svg';

const StyledSubmenu = styled.ul`
  padding: 1rem 0.25rem;
  list-style: none;

  .submenu__link-name,
  .description {
    display: block;
  }

  .submenu__link-name {
    font-size: 1.25rem;
    line-height: 1.24;
  }

  .description {
    font-size: 0.85rem;
    color: var(--text-color);
  }

  .submenu__link-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem 0;
  }
`;

const StyledChevron = styled(Chevron)`
transition: transform 0.2s ease-out;
 transform: ${(props) => (props.open ? 'rotate(180deg)' : 'rotate(0)')};
}
`;

const AccordionPanel = styled.div`
  overflow: hidden;
  transition: max-height 0.4s ease-out;
  display: block;
  max-height: ${(props) => (props.open ? '500px' : '0')};
  height: auto;

  button {
    padding: 0;
  }

  .submenu {
  }
`;

export default function Accordion(props) {
  const [accordionOpen, toggleAccordionOpen] = useState(false);

  function toggleAccordion() {
    toggleAccordionOpen(!accordionOpen);
  }

  return (
    <div>
      <button onClick={toggleAccordion}>
        {props.title}
        <StyledChevron open={accordionOpen} className="chevron" />
      </button>
      <AccordionPanel open={accordionOpen}>
        <StyledSubmenu>
          {props.subMenu.map((subLink) => (
            <li
              key={subLink.name}
              className={
                subLink.description ? 'has-description' : 'no-description'
              }
            >
              <Link
                className="submenu__link-container"
                onClick={props.closeMobileMenu}
                to={subLink.link}
              >
                <span className="submenu__link-name">{subLink.name}</span>

                {subLink.description &&
                  false && ( // remove false if we want descriptions
                    <span className="description">{subLink.description}</span>
                  )}
              </Link>
            </li>
          ))}
        </StyledSubmenu>
      </AccordionPanel>
    </div>
  );
}
