import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import styled from 'styled-components';
import { useDarkMode } from 'usehooks-ts';

const StyledButton = styled.button`
  display: inline-block;
  padding: 1rem 1.75rem;
  background: ${(props) =>
    props.darkMode ? 'var(--button-bg-color);' : '#D12727; !important'};
  border-radius: var(--border-radius-medium);
  border: none;
  font-family: var(--button-font);
  font-size: var(--button-size);
  color: var(--button-color);
  text-decoration: none;
  line-height: 1;
  cursor: pointer;

  &:hover {
    background-color: var(--button-hover-bg-color);
    color: var(--button-hover-color);
  }

  &.button--small {
    padding: 0.75rem;
    font-size: 0.95rem;
  }

  &.button--large {
    padding: 1.3rem;
    font-size: 1.2rem;
  }
`;

const StyledLink = styled(GatsbyLink)`
  display: inline-block;
  padding: 1rem 1.75rem;
  background-color: var(--button-bg-color);

  background: ${(props) =>
    props.darkMode ? 'var(--button-bg-color);' : '#D12727;'};
  border-radius: var(--border-radius-medium);
  font-family: var(--button-font);
  font-size: var(--button-size);
  color: var(--button-color);
  text-decoration: none;
  line-height: 1;

  &:hover {
    background-color: var(--button-hover-bg-color);
    color: var(--button-hover-color);
  }

  &.button--small {
    padding: 0.75rem;
    font-size: 0.95rem;
  }

  &.button--large {
    padding: 1.3rem;
    font-size: 1.2rem;
  }
`;

export default function Button({ children, url, classes, onClick, disabled }) {
  const { isDarkMode } = useDarkMode();
  const buttonClass = `button ${classes}`;
  return (
    <>
      {url ? (
        <StyledLink darkMode={isDarkMode} to={url} className={buttonClass} onClick={onClick}>
          {children}
        </StyledLink>
      ) : (
        <StyledButton darkMode={isDarkMode} className={buttonClass} onClick={onClick} disabled={disabled}>
          {children}
        </StyledButton>
      )}
    </>
  );
}
