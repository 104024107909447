import { createGlobalStyle } from 'styled-components';

const Typography = createGlobalStyle`

	:root {
    /* Font Families */
    --system-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    --body-font-family: 'greycliff-cf', 'Greycliff CF', var(--system-font);
    --heading-font-family: 'greycliff-cf', 'Greycliff CF', var(--system-font);

    /* Body */
    --body-font-size: 1.25rem;
    --body-line-height: 1.7;

    --font-size-small: 1.125rem;
    --font-size-xs: .968rem;
    --font-size-xxs: .8125rem;

    /* Headings */
    --h1-size: 2.8rem;
    --h2-size: 2.8rem;
    --h3-size: 2.2rem;
    --h4-size: 1.8rem;
    --h5-size: 1.56rem;
    --h6-size: 1.25rem;

    @media(min-width: 768px) {
      --h1-size: 3.5rem;
      --h2-size: 3.5rem;
    }

    @media(min-width: 992px) {
      --h1-size: 4.375rem;
      --h2-size: 4.375rem;
    }

    --heading-line-height: 1.2;

    --button-size: 1rem;

    /* Forms */
    --label-font-size: 1rem;
	}

	/* Light Colours */
  :root.light {
    --heading-color: #09123B;
  }

  /* Dark Colours */
  :root.dark {
    --heading-color: #fff;
  }

  body {
    font-family: var(--body-font-family);
    font-size: var(--body-font-size);
    line-height: var(--body-line-height);
  }

  h1, h2, h3, h4, h5 {
    color: var(--heading-color);
    font-family: var(--heading-font-family);
    line-height: var(--heading-line-height);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-top: 0;

    &.gradient {
      display: inline-block;
      position: relative;

      &::after {
        position: absolute;
        content: '';
        bottom: -7px;
        left: 0;
        width: 100%;
        height: 6px;
        background: linear-gradient(to right, #3954D8, #F45858);
      }
    }
  }

  strong {
    font-weight: 600 !important;
  }

  :root.light {
    h1, h2, h3, h4, h5 {
      &.gradient {
        &::after {
         background: linear-gradient(90deg, #3246A2, 0%, #D12727 100%);
        }
      }
    }
  }



  h1 {
    font-size: var(--h1-size);
  }

  h2 {
    font-size: var(--h2-size);
    margin-bottom: 4.375rem;
    line-height: 1.1;

    @media(min-width: 992px) {
      margin-bottom: 7.125rem;
    }
  }

  h3 {
    font-size: var(--h3-size);
    font-weight: 500;
    margin-bottom: 1rem;

    @media(min-width: 768px) {
      margin-bottom: 1.375rem;
    }
  }

  h4 {
    font-size: var(--h4-size);

    &.gradient {
      &::after {
        width: 38px;
        height: 2px;
      }
    }
  }

  h5 {
    font-size: var(--h5-size);
  }

  .font-size-large {
    font-size: var(--font-size-large);
  }

  .font-size-small {
    font-size: var(--font-size-small);
  }

  .meta-label {
    display: inline-block;
    font-size: var(--font-size-xxs);
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 1.7px;
    opacity: 0.8;
    line-height: 1.1;
  }

  .text-content {
    a {
      color: var(--pixel-light-red);
      text-decoration: none;
      transition: color 0.2s ease-in;

      &:hover, &:visited {
        color: var(--pixel-red);
      }
    }

    li {
       line-height: 1.4;
       margin-bottom: 0.5rem;
       ul {
         margin-bottom: 1rem;
       }

       & > ul {
         margin-top: 0.85rem;
       }
     }
     ul {
       margin-bottom: 2.1875rem;
     }

    &--job-posting {
      @media(min-width:992px) {
        margin: 0 auto;
        max-width: 70ch;

       li {
         line-height: 1.4;
         margin-bottom: 0.5rem;
         ul {
           margin-bottom: 1rem;
         }

         & > ul {
           margin-top: 0.85rem;
         }
       }
       ul {
         margin-bottom: 2.1875rem;
       }
      }
    }

    &--longform { // for long text pages, like privacy
      p, li, address {
        font-size: 1.1rem;
      }
      h2 {
        font-size: 3rem;
        margin-bottom: 2rem;
      }
      h4 {
        margin-bottom: 0.5rem;
        font-size: 1.25rem;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 0.7px;
      }
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 2.1875rem;

    &:last-of-type {
      // margin-bottom: 0;
    }
  }

  ol {
    display: flex;
    flex-direction: column;
    gap: 1.375rem;
  }

  ul.list-reset {
    padding-left: 0;
  }

  .font-normal {
    font-style: normal;
  }

`;

export default Typography;
