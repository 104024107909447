import React from 'react';
import Layout from './src/components/Layout';
import { AnimatePresence } from 'framer-motion';

export function wrapPageElement({ element, props }) {
  return (
    <Layout {...props}>
      <AnimatePresence mode="wait" initial={false}>
        {element}
      </AnimatePresence>
    </Layout>
  );
}
