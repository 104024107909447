import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
	:root {
    /* Breakpoints (felt cute, might delete later) */
    --mq-small: 576px;
    --mq-medium: 768px;
    --mq-large: 992px;
    --mq-extra-large: 1340px;

    /* Global Content Container + Padding */
    --container-width: 1500px;
    --padding-x-12: 1.25rem;
    --padding-x-10: 9rem;
    --padding-x-8: 12rem;
    --section-padding-y: 4.125rem;

    @media(min-width: 768px) {
      --padding-x-12: 3rem;
    }

    /* Global Colours */
    --pixel-blue: #3954D8;
    --pixel-red: #F45858;
    --pixel-light-red: #F8ABAB;
    --pixel-light-blue: #9FA9D5;
    --navy: #162677;
    --dark-navy: #14132C;
    --blue-gray: #666C83;
    --soft-light: #F4EBE2;
    --contrast-grey: #EFEFEF;
    --almost-black: #09123B;
    --pixel-dark-blue-gradient-start: rgba(57, 86, 216, 0.90);
    --pixel-dark-blue-gradient-end: rgba(10, 12, 56, 0.90);
    --pixel-light-gradient-start: rgba(239, 239, 239, 0.90);
    --pixel-light-gradient-end: rgba(244, 235, 226, 0.90);

    /* Borders */
    --border-radius-small: 5px;
    --border-radius-medium: 10px;
    --border-radius-large: 15px;

    /* Button */
    --button-font-size: 1rem;
    --button-bg-color: var(--pixel-red);
    --button-hover-bg-color: var(--pixel-blue);
    --button-color: #FFFFFF;
    --button-hover-color: #FFFFFF;

    /* Angled Edges */
    --clip-path-bottom-right: polygon(0 0, 100% 0, 100% 76%, 0 100%);
  }

  :root.light {
    /* Colours */
    --bg-color: #F8F8F8;
    --text-color: #464560;
    --nav-item-color: #0d1a5d;
    --nav-button-bg-color: #09123B;
    --nav-button-text-color: #F8F8F8;
    --mobile-menu-bg-color: var(--soft-light);
  }

  :root.dark {
    /* Colours */
    --bg-color: #14132C;
    --text-color: #DCDEEF;
    --form-item-color: #F8F8F8;
    --nav-item-color: #fff;
    --nav-button-bg-color: #fff;
    --nav-button-text-color: #3650D0;
    --mobile-menu-bg-color: var(--almost-black);
  }

  html {
    scroll-behavior: smooth;
  }

  html * {
    box-sizing: border-box;
    cursor: none !important;
  }

  body {
    background-color: var(--bg-color);
    text-color: var(--text-color);
    color: var(--text-color);
    // transition-property: background, background-color;
    // transition: 0.2s ease;
  }

  a:not(.button):visited {
    color: var(--pixel-red);
  }

  main {
    padding-bottom: 5rem;
  }

  #gatsby-focus-wrapper {
    overflow-x: hidden;
  }

  .container {
    max-width: var(--container-width);
    margin: 0 auto;
  }

  /* Padding Utility Classes */
  .padding-x-12 {
    padding-left: var(--padding-x-12);
    padding-right: var(--padding-x-12);
  }

  .padding-x-10 {
    padding-left: var(--padding-x-12);
    padding-right: var(--padding-x-12);

    @media(min-width: 992px) { // 768
      padding-left: var(--padding-x-10);
      padding-right: var(--padding-x-10);
    }
  }

  .padding-y-bottom {
    padding-bottom: var(--section-padding-y);
  }

  .padding-x-8 {
    padding-left: var(--padding-x-12);
    padding-right: var(--padding-x-12);

    @media(min-width: 992px) {
      padding-left: var(--padding-x-8);
      padding-right: var(--padding-x-8);
     }
  }

  .section-padding-y {
    padding-top: var(--section-padding-y);
    padding-bottom: var(--section-padding-y);

    @media(min-width: 768px) {
      --section-padding-y: 6.125rem;
    }

    @media(min-width: 992px) {
      --section-padding-y: 8.125rem;
    }
  }

  /* Background Classes */
  :root.light .bg-blue-gradient {
    background: transparent linear-gradient(253deg, var(--contrast-grey) 0%, var(--soft-light) 100%) 0% 0% no-repeat padding-box;
  }

  :root.dark .bg-blue-gradient {
    background: rgb(10,14,64);
    background: linear-gradient(90deg, rgba(10,14,64,1) 0%, rgba(58,84,214,1) 100%);
  }

  .bg-blue-red-gradient {
    background: linear-gradient(90deg, var(--pixel-blue) 0%, var(--pixel-red) 100%);
  }

  .dynamic-gradient  {
    background: linear-gradient(90deg, var(--pixel-blue) 0%, var(--pixel-red) 100%);
  }

  :root.dark {
    .dynamic-gradient  {
      background: linear-gradient(90deg, var(--pixel-blue) 0%, var(--pixel-red) 100%);
    }
  }

  :root.light {
    .dynamic-gradient  {
      background: linear-gradient(90deg, #3246A2, 0%, #D12727 100%);
    }
  }


  :root.light .bg-dark-gradient {
    background: var(--bg-color);
  }

  :root.dark .bg-dark-gradient {
    background: var(--dark-navy);
    background: linear-gradient(2deg, #14132c 10%, #1C1B40 50%, #15132c 90%);
  }

  /* Flourishes */
  .angled-bottom {
    clip-path: var(--clip-path-bottom-right);
  }

  .floating-pixel {
    --rotation: 45deg;
    width: 1.4rem;
    height: 1.4rem;
    background-color: var(--pixel-blue);
    animation: float 6s ease-in-out infinite;

    &--red {
      background-color: var(--pixel-red);
    }

    &--gray {
      background-color: var(--blue-gray);
    }

    &--md {
      width: 2rem;
      height: 2rem;
    }

    &--lg {
      width: 3rem;
      height: 3rem;
    }

    &--xl {
      width: 6.75rem;
      height: 6.75rem;
    }

    &--xxl {
      width: 13.5rem;
      height: 13.5rem;
    }

    &--sm {
      width: 1.25rem;
      height: 1.25rem;
    }

    &--xs {
      width: .85rem;
      height: .85rem;
    }

    &--xxs {
      width: .65rem;
      height: .65rem;
    }

    &--rotate-26 {
      --rotation: 26deg;
    }

    &--rotate-30 {
      --rotation: 30deg;
    }

    &--rotate-35 {
      --rotation: 35deg;
    }

    &--rotate-40 {
      --rotation: 40deg;
    }

    &--rotate-50 {
      --rotation: 50deg;
    }

    &--rotate-67 {
      --rotation: 67deg;
    }

    &--rotate-70 {
      --rotation: 70deg;
    }

    &--rotate-120 {
      --rotation: 120deg;
    }

    &--opacity-10 {
      opacity: .10;
    }

    &--opacity-15 {
      opacity: .15;
    }

    &--opacity-25 {
      opacity: .25;
    }

    &--opacity-50 {
      opacity: .5;
    }

    &--opacity-70 {
      opacity: .7;
    }
  }

  .heading--decorative-diamond::after {
    display: inline-block;
    content: '';
    width: 0.7rem;
    height: 0.7rem;
    margin-left: 0.5rem;
    transform: rotate(45deg);
    background-color: var(--pixel-red);
  }

  /* red diamond list */
  ul.list--red-diamond {
    padding: 0;
    list-style-type: none;

    li {
      position: relative;
      padding-left: 1.5em;

      &:after {
        content: '';
        height: 8px;
        width: 8px;
        background: var(--pixel-red);
        display: block;
        position: absolute;
        transform: rotate(45deg);
        top: 12px;
        left: 0;
      }
    }
  }

  /* Flex Utility Classes */
  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Utility Classes */
  .w-100 {
    width: 100%;
  }

  .relative {
    position: relative;
  }

  /* Animations */
  @keyframes float {
    0% {
      box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.09);
      transform: translatey(0px) rotate(var(--rotation));
    }
    50% {
      box-shadow: 0 15px 15px 0px rgba(0, 0, 0, 0.06);
      transform: translatey(-20px) rotate(var(--rotation));
    }
    100% {
      box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.09);
      transform: translatey(0px) rotate(var(--rotation));
    }
  }

  /* Visibilty Classes */
  .d-none {
    display: none;
  }

  .d-lg-block {
    @media(min-width:992px) {
      display: block;
    }
  }

  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default GlobalStyles;
