import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Logo from '../assets/images/logo-white.svg';
import Hare from '../assets/images/icon-hare.svg';
import Whale from '../assets/images/icon-whale.svg';
import Contact from '../assets/images/icon-contact.svg';
import PreFooter from './PreFooter';

const StyledFooter = styled.footer`
  --footer-text-color: #dcdeef;
  --footer-bg-color: #14132c;

  padding-top: 5rem;
  padding-bottom: 5rem;
  font-size: var(--font-size-xs);
  color: var(--footer-text-color);
  background-color: var(--footer-bg-color);

  .footer-grid {
    @media only screen and (min-width: 768px) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
      gap: 3rem;
    }
  }

  .colophon {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  a {
    color: var(--footer-text-color);
    text-decoration: none;
    line-height: 1.3;
    font-size: 0.96875rem;

    &:hover {
      color: var(--pixel-light-red);
    }
  }

  .footer-group {
    margin-bottom: 2rem;

    @media (min-width: 992px) {
      margin-bottom: 0;
    }
  }

  .footer-top {
    padding-bottom: 3rem;
    display: flex;
    flex-wrap: wrap;

    .footer-grid {
      flex: 0 0 70%;
      min-width: 788px;
    }
  }

  .footer-bottom {
    padding-top: 3rem;
  }

  .footer-logo {
    width: 27%;
    margin-bottom: 3rem;
    padding-right: 5rem;
  }

  .addressblock {
    svg {
      height: 36px;
    }

    &.mail {
      svg {
        height: 32px;
      }
    }
  }

  address {
    margin-bottom: 3rem;
    font-weight: 400;
    font-style: normal;
    line-height: 1.5;
    font-size: 0.96875rem;
  }

  nav {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  h4 {
    color: var(--footer-text-color);
    margin: 0 0 1.125rem 0;

    &.gradient {
      margin-bottom: 2rem;
    }
  }

  .footer-floor {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
    font-size: 0.8125rem;

    a {
      font-size: 0.8125rem;
    }

    nav {
      display: flex;
      flex-direction: row;
      gap: 2.5rem;
    }
  }
`;

export default function Footer(props) {
  return (
    <>
      <PreFooter />
      <StyledFooter className="padding-x-12">
        <div className="footer-top">
          <Link to="/" className="footer-logo">
            <Logo />
          </Link>
          <div className="footer-grid">
            <div className="addressblock">
              <Hare />
              <h4>Edmonton</h4>
              <address>
                <a href="tel:780 887 3897">780 887 3897</a> <br />
                <a href="mailto:YEG@wearepixel.ca">YEG@wearepixel.ca</a>
              </address>
            </div>
            <div className="addressblock">
              <Whale />
              <h4>Victoria</h4>
              <address>
                <a href="mailto:YYJ@wearepixel.ca">YYJ@wearepixel.ca</a>
              </address>
            </div>
            <div className="addressblock mail">
              <Contact />
              <h4>Send Us Mail</h4>
              #204, 12408 108 Ave. NW
              <br />
              Edmonton, AB. T5M 0H3
            </div>
          </div>
        </div>
        {false && (
          <div className="footer-bottom footer-grid">
            <div className="footer-group" />
            <div className="footer-group">
              <h4 className="gradient">Who We Are</h4>
              <nav>
                <Link to="about">About Us</Link>
              </nav>
            </div>
            <div className="footer-group"></div>
            <div className="footer-group"></div>
          </div>
        )}
        <div className="footer-floor">
          <div className="colophon">
            <span>© {new Date().getFullYear()} Pixel</span>
            <Link to="/colophon">Colophon</Link>
          </div>
          <div className="colophon">
            <Link to="/privacy">Privacy Policy</Link>
            <Link to="/terms-of-service">Terms</Link>
          </div>
        </div>
      </StyledFooter>
    </>
  );
}
