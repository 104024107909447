import React, { useState, useRef, useEffect } from 'react';
import { useOnClickOutside, useDarkMode } from 'usehooks-ts';
import { Link } from 'gatsby';
import styled from 'styled-components';

import ThemeToggle from './ThemeToggle';
import MobileNav from './MobileNav';
import LightLogo from '../assets/images/logo-color-black-wordmark.svg';
import DarkLogo from '../assets/images/logo-color-white-wordmark.svg';
import Hamburger from '../assets/images/hamburger.svg';

const NavStyles = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 22px;
  border-bottom: ${(props) =>
    props.darkMode
      ? '1px solid rgba(248, 248, 248, 0.23)'
      : props.menuOpen
      ? '1px solid #f4f5f9'
      : '1px solid transparent'};
  font-weight: 500;
  z-index: 100;
  background: ${(props) =>
    !props.menuOpen ? 'transparent' : props.darkMode ? 'var(--navy)' : 'white'};

  @media (min-width: 992px) {
    padding: 23px 45px;
  }

  .pixel-logo {
    width: 135px;

    @media (min-width: 992px) {
      width: 180px;
    }
  }

  .nav-right {
    display: none;
    padding-left: 0;

    @media (min-width: 992px) {
      display: flex;
      align-items: center;
    }
  }

  .hamburger-button {
    @media (min-width: 992px) {
      display: none;
    }

    path {
      fill: ${(props) =>
        props.darkMode ? 'var(--contrast-grey)' : 'var(--almost-black)'};
    }
  }

  ul {
    display: flex;
    gap: 2.2rem;
    margin: 0;
    list-style: none;
  }

  button,
  a {
    appearance: none;
    border: none;
    background: none;
    font-size: 1rem;
    font-weight: 500;
    transition: color 0.3s;
    color: var(--nav-item-color);
    text-decoration: none;

    &:hover {
      color: var(--red);
      cursor: pointer;
    }
  }

  .header-button a {
    margin: 0;
    padding: 0.75rem 1.61rem;
    transition: all 0.25s;
    border-radius: 50px;
    color: var(--nav-button-text-color);
    background-color: var(--nav-button-bg-color);
    font-size: 1rem;
    text-decoration: none;

    &:hover {
      color: #fff;
      background-color: var(--pixel-red);
    }
  }
`;

const StyledSubMenu = styled.div`
  position: absolute;
  top: 101px;
  left: 0;
  z-index: 99;
  justify-content: center;
  padding: 2rem 0;
  width: 100%;
  background-color: ${(props) => (props.darkMode ? 'var(--navy)' : 'white')};
  transition: all 0.2s ease-out;
  border-bottom: 1px solid rgba(248, 248, 248, 0.23);
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.isOpen ? '1' : '0')};

  .submenu__link {
    transition: color 0.2s ease-out;
    line-height: 1.2;
    font-size: 0.9rem;

    @media (min-width: 1200px) {
      font-size: 1rem;
    }

    &:hover {
      color: var(--pixel-red);
    }
    span {
      display: block;
    }

    &:visited {
      color: ${(props) => (props.darkMode ? 'white' : 'var(--navy)')} !important;
    }
  }

  ul {
    margin: 0 1rem;
    display: flex;
    justify-content: center;
    padding-left: 0;
    list-style: none;
  }

  a {
    color: var(--text);
    font-size: 1rem;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.2s;

    &:hover {
      color: var(--pixel-red);
    }
  }

  li.has-description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 220px;

    .submenu__link {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 11px;
      height: 100%;
      min-height: 95px;
    }

    a {
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
      color: var(--text);
      line-height: 1.22;
    }

    .submenu__description {
      margin-top: auto;
    }
  }

  .description {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.42;
  }
`;

export default function Nav({ menuLinks }) {
  const ref = useRef(null);
  const handleClickOutside = () => {
    toggleMenuOpen(false);
    toggleMenuCategory('');
  };

  useOnClickOutside(ref, handleClickOutside);

  const [menuCategory, toggleMenuCategory] = useState(false);
  const [menuOpen, toggleMenuOpen] = useState(false);
  const [mobileMenuOpen, toggleMobileMenuOpen] = useState(false);

  function closeMobileMenu() {
    toggleMobileMenuOpen(false);
  }

  function openMobileMenu() {
    toggleMobileMenuOpen(true);
  }

  const handleNavItem = (event) => {
    toggleMenuCategory(event.target.dataset.category);
    toggleMenuOpen(true);
  };

  const { isDarkMode } = useDarkMode();
  useEffect(() => {
    // update body class every time the theme changes
    document.documentElement.className = isDarkMode ? 'dark' : 'light';
  }, [isDarkMode]);

  return (
    <>
      <NavStyles
        ref={ref}
        menuOpen={menuOpen}
        menuCategory={menuCategory}
        darkMode={isDarkMode}
      >
        <Link to="/" className="flex-center">
          {isDarkMode ? (
            <DarkLogo className="pixel-logo" />
          ) : (
            <LightLogo className="pixel-logo" />
          )}
        </Link>
        {/* The following doesn't seem to do anything, yet without it */}
        {/* the build produces navbar glitches in dark mode. Curious! */}
        {isDarkMode ? '' : <span></span>}
        <ul className="nav-right">
          {menuLinks.map((link) => (
            <li key={link.name} className={link.class}>
              {link.parent ? (
                <button
                  type="button"
                  className="dropdown-trigger"
                  onClick={handleNavItem}
                  data-category={link.name}
                >
                  {link.name}
                </button>
              ) : (
                <Link className={link.class} to={link.link}>{link.name}</Link>
              )}

              {link.subMenu && link.subMenu.length > 0 ? (
                <StyledSubMenu
                  isOpen={menuCategory === link.name}
                  darkMode={isDarkMode}
                >
                  <ul aria-label="submenu">
                    {link.subMenu.map((subLink) => (
                      <li
                        className={subLink.description ? 'has-description' : ''}
                        key={subLink.name}
                      >
                        <Link
                          className="submenu__link"
                          to={subLink.link}
                          onClick={handleClickOutside}
                        >
                          <span className="submenu__link-name">
                            {subLink.name}
                          </span>

                          {subLink.description && (
                            <span className="description submenu__description">
                              {subLink.description}
                            </span>
                          )}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </StyledSubMenu>
              ) : null}
            </li>
          ))}
          <ThemeToggle />
        </ul>
        <button
          type="button"
          className="hamburger-button"
          onClick={openMobileMenu}
        >
          <Hamburger />
        </button>
      </NavStyles>
      <MobileNav
        menuLinks={menuLinks}
        mobileMenuOpen={mobileMenuOpen}
        closeMobileMenu={closeMobileMenu}
      />
    </>
  );
}
