import * as React from 'react';
import { useDarkMode } from 'usehooks-ts';
import { Link } from 'gatsby';
import styled from 'styled-components';

import ThemeToggle from './ThemeToggle';
import Accordion from '../components/Accordion';
import Logo from '../assets/images/logo-white.svg';
import Close from '../assets/images/close.svg';

const MobileNavStyles = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100vw;
  background-color: var(--mobile-menu-bg-color);
  min-height: 100vh;
  transition: transform 0.5s ease-in-out;
  opacity: ${(props) => (props.open ? '1' : '0')};
  transform: ${(props) =>
    props.open ? 'translate(0,0)' : 'translate(100%, 0)'};

  header {
    display: flex;
    padding: 10px 22px;
    justify-content: space-between;
    align-items: center;
    border-bottom: ${(props) => (props.darkMode ? '1px solid rgba(248, 248, 248, 0.23)' : '1px solid transparent')};
  }

  .pixel-logo {
    width: 135px;

    @media (min-width: 992px) {
      180px;
    }

    path,
    #Rectangle_900 {
      fill: ${(props) => (props.darkMode ? 'white' : 'black')};
    }

    rect {
      fill: var(--nav-item-color);
    }
  }

  ul.main-menu {
    display: flex;
    flex-direction: column;
    padding-top: 150px;
    padding-bottom: 3rem;
    list-style: none;
    gap: 25px;
    position: relative;
    margin: 0 2rem;
    padding-left: 0;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 6px;
      width: 100%;
      background: linear-gradient(90deg, var(--pixel-blue) 0%, var(--pixel-red) 100%);
    }
  }

  .mobile-menu-buttons {
    display: flex;
    align-items: center;
    gap: 40px;
    margin-right: 12px;

    .theme-toggle {
      margin-top: 0;
    }

    .mobile-menu-close-button {
      cursor: pointer;

      path {
        fill: var(--nav-item-color);
      }
    }
  }

  button, a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    appearance: none;
    border: none;
    background: none;
    font-size: 2.1875rem;
    font-weight: 500;
    transition: color 0.3s;
    color: var(--nav-item-color);
    text-decoration: none;

    @media(min-width: 576px) {
      width: 450px;
    }
  }

  .chevron path {
    fill: var(--nav-item-color);
  }
`;

export default function MobileNav(props) {
  const { isDarkMode } = useDarkMode();
  return (
    <MobileNavStyles
      open={props.mobileMenuOpen}
      darkMode={isDarkMode}
    >
      <header>
        <Link onClick={props.closeMobileMenu} to="/" className="flex-center">
          <Logo className="pixel-logo" />
        </Link>
        <div className="mobile-menu-buttons">
          <ThemeToggle />
          <Close
            onClick={props.closeMobileMenu}
            className="mobile-menu-close-button"
          />
        </div>
      </header>
      <ul className="main-menu">
        {props.menuLinks.map((link, index) => (
          <li key={link.name}>
            {link.subMenu && link.subMenu.length > 0 ? (
              <Accordion
                title={link.name}
                subMenu={link.subMenu}
                closeMobileMenu={props.closeMobileMenu}
              />
            ) : (
              <Link
                onClick={props.closeMobileMenu}
                to={link.link}>{link.name}
              </Link>
            )}
          </li>
        ))}
      </ul>
    </MobileNavStyles>
  );
}
