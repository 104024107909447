import React from 'react';
import { useDarkMode } from 'usehooks-ts';
import Toggle from 'react-toggle';
import styled from 'styled-components';

import LightSwitch from '../static/images/toggle-light-mode.svg';
import DarkSwitch from '../static/images/toggle-dark-mode.svg';

const ThemeToggleStyles = styled.div`
  display: flex;
  margin-top: 5px;

  .nav-theme-toggle .react-toggle-thumb {
    top: -5px;
    left: -10px;
    width: 34px;
    height: 34px;
    background: url(${LightSwitch});
    border: none;
  }

  .nav-theme-toggle.react-toggle--checked .react-toggle-thumb {
    left: 27px;
    background: url(${DarkSwitch});
  }

  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #F28787; // slightly darker --pixel-light-red
  }

  .nav-theme-toggle .react-toggle-track {
    background-color: var(--pixel-light-red);

    &:hover {
      background-color: var(--pixel-red);
    }
  }

  .react-toggle.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #7587d6;
  }

  .nav-theme-toggle.react-toggle--checked .react-toggle-track {
    background-color: var(--pixel-light-blue);

    &:hover {
      background-color: #282C5D;
    }
  }
`;

export default function ThemeToggle(props) {
  const { isDarkMode, toggle } = useDarkMode();

  return (
    <ThemeToggleStyles
      darkMode={isDarkMode}
      className={`theme-toggle`}
    >
      <Toggle
        className="nav-theme-toggle"
        checked={isDarkMode}
        icons={false}
        onChange={toggle} // update isDarkMode
      />
    </ThemeToggleStyles>
  );
}
